<template>
  <div class="page-container">
    <h2 class="w-100 text-center">Impedanzmessung</h2>
    <template v-if="showComplexGraph === true">
        <DataChart :chart-data="realChartData" :options="realChartOptions" :styles="chartStyleA"/>
        <DataChart :chart-data="imagChartData" :options="imagChartOptions" :styles="chartStyleA"/>
    </template>
    <template v-else>
      <div class="chart-container">
        <DataChart :chart-data="impChartData" :options="impChartOptions" :styles="chartStyleB"/>
      </div>
      <div class="chart-container">
        <DataChart :chart-data="magChartData" :options="magChartOptions" :styles="chartStyleB"/>
      </div>  
    </template>
    <b-form-checkbox
        id="cbGraphSelect"
        v-model="cbGraphSelectState"
        name="cbGraphSelect">
        Complex
    </b-form-checkbox>
    <div class="form-container">
      <b-form class="form-content" @submit="onSubmit">
        <b-container>
          <b-form-row>
            <b-col>
              <b-form-group id="input-group-fstart" label="Start Frequenz (Hz)" label-for="fstart-input">
                <b-form-input 
                  id="fstart-input" 
                  v-model="form.fStart"
                  :state="inputFstartState" 
                  type="number" 
                  size="sm"
                  required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="input-group-fstep" label="Inkrement (Hz)" label-for="fstep-input">
                <b-form-input 
                  id="fstep-input" 
                  v-model="form.fStep" 
                  :state="inputFstepState"
                  type="number" 
                  size="sm"
                  required></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="input-group-ninc" label="Anzahl Inkremente" label-for="ninc-input">
                <b-form-input 
                  id="ninc-input" 
                  v-model="form.nInc" 
                  :state="inputNincState"
                  type="number" 
                  size="sm"
                  required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col class="col-style">
              <b-button class="submit-button" size="sm" :disabled="submitDisable" variant="danger" @click="startCalibration()">Kallibirieren</b-button>
              <b-button class="submit-button" type="submit" size="sm" :disabled="submitDisable" variant="primary">Messen</b-button>
            </b-col>
          </b-form-row>
        </b-container>
      </b-form>
    </div>
  </div>
</template>

<script>
import DataChart from '../components/DataChart.js'

export default {
  name: "DataGraphBioz",
  components: {
    DataChart,
  },

  data () {
    return {     
      sImpedance: {
        cComplexUuid: '00002221-702b-69b5-b243-d6094a2b0e24',
        cComplexConfUuid: '00002222-702b-69b5-b243-d6094a2b0e24',
        cCalibUuid: '00002223-702b-69b5-b243-d6094a2b0e24'
      },

      form: {
        fStart: 4500,
        fStep: 200,
        nInc: 50
      },

      chartStyleA: {
        marginRight: 0,
        paddingBottom: '10px',
        display: 'inline-block',
        height: '32%',
        width: '95%'
      },

      chartStyleB: {
        height: '100%',
      },

      realChartOptions: {
        animation: {
          duration: 0
        },
        title: {
          display: true,
          text: "Real",
          fontColor: '#2c3e50'
        },
        legend: {
          display: false,
          labels: {
            boxWidth: 0,
            boxHeight: 0,
          }
        },
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Frequenz in Hz',
              fontColor: '#2c3e50'
            },
            ticks: {
              userCallback(value) {
                if(value >= 1000) {
                  value /= 1000
                  return value.toLocaleString() + 'k'
                }
                return value
              }
            }
          }],
          yAxes: [{
            ticks: {
              userCallback(value) {
                if(value >= 1000) {
                  value /= 1000
                  return value.toLocaleString() + 'k'
                }
                return value
              }
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
      },

      imagChartOptions: {
        animation: {
          duration: 0
        },
        title: {
          display: true,
          text: "Imaginär",
          fontColor: '#2c3e50'
        },        
        legend: {
          display: false,
          labels: {
            boxWidth: 0,
            boxHeight: 0,
          }
        },
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Frequenz in Hz',
              fontColor: '#2c3e50'
            },
            ticks: {
              userCallback(value) {
                if(value >= 1000) {
                  value /= 1000
                  return value.toLocaleString() + 'k'
                }
                return value
              }
            }
          }],
          yAxes: [{
            ticks: {
              userCallback(value) {
                if(value >= 1000) {
                  value /= 1000
                  return value.toLocaleString() + 'k'
                }
                return value
              }
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
      },

      impChartOptions: {
        animation: {
          duration: 0
        },
        title: {
          display: true,
          text: "Impedanz",
          fontColor: '#2c3e50'
        },        
        legend: {
          display: false,
          labels: {
            boxWidth: 0,
            boxHeight: 0,
          }
        },
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Frequenz in Hz',
              fontColor: '#2c3e50'
            },
            ticks: {
              userCallback(value) {
                if(value >= 1000) {
                  value /= 1000
                  return value.toLocaleString() + 'k'
                }
                return value
              }
            }
          }],
          yAxes: [{
            ticks: {
              userCallback(value) {
                if(value >= 1000) {
                  value /= 1000
                  return value.toLocaleString() + 'k'
                }
                return value
              }
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
      },      

      magChartOptions: {
        animation: {
          duration: 0
        },
        title: {
          display: true,
          text: "Magnitude",
          fontColor: '#2c3e50'
        },        
        legend: {
          display: false,
          labels: {
            boxWidth: 0,
            boxHeight: 0,
          }
        },
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Frequenz in Hz',
              fontColor: '#2c3e50'
            },
            ticks: {
              userCallback(value) {
                if(value >= 1000) {
                  value /= 1000
                  return value.toLocaleString() + 'k'
                }
                return value
              }
            }
          }],
          yAxes: [{
            ticks: {
              userCallback(value) {
                if(value >= 1000) {
                  value /= 1000
                  return value.toLocaleString() + 'k'
                }
                return value
              }
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
      },

      realChartData: null,
      imagChartData: null,
      impChartData: null,
      magChartData: null,

      scanStarted: false,
      lastFrequency: 0,

      gainFactor: 1.0266134609082656e-8,
      gainRefResisor: 20000,
      curImpAvg: 0,

      cbGraphSelectState: false,
    }
  },

  computed: {
    inputFstartState() {
      if(this.form.fStart < 500) return false
      if(this.form.fStart > 100000) return false
      return true
    },
    inputFstepState() {
      if(this.form.fStep < 0) return false
      if(this.form.fStep > 65535) return false
      return true
    },
    inputNincState() {
      if(this.form.nInc < 0) return false
      if(this.form.nInc > 65535) return false
      /* TODO: Doesn't work for some reason */
      //if((this.form.fStart + (this.form.nInc * this.form.fStep)) > 100000) return false

      return true
    },
    submitDisable() {
      return !(this.inputFstartState && this.inputFstepState && this.inputNincState)
    },
    showComplexGraph() {
      return this.cbGraphSelectState
    }
  },

  methods: {
    async onSubmit(event) {
      event.preventDefault()

      if(this.submitDisable) return
      if(!this.$store.state.sImpedanceAvailable) return

      this.lastFrequency = parseInt(this.form.fStart) + (parseInt(this.form.nInc) * parseInt(this.form.fStep))
      // console.log(this.form.fStart + " + (" + this.form.nInc + " * " + this.form.fStep + ")")
      // console.log("last frequency: " + this.lastFrequency)

      this.$store.commit('clearImpData')

      await this.startScan()

      this.$store.state.impHandles.complexData.addEventListener(
                                            'characteristicvaluechanged', 
                                            this.impedanceEventHandler)

      await this.$store.state.impHandles.complexData.startNotifications()
    },

    async startScan() {
      let scanConf = this.parseScanConf()
      //console.log(scanConf)

      if(this.scanStarted) {
        await this.stopScan()
      }

      await this.$store.state.impHandles.complexConf.writeValue(scanConf)

      this.curImpAvg = 0
      this.scanStarted = true
    },

    async stopScan() {
      const buf = new ArrayBuffer(9)
      let scanStopConf = new Uint8Array(buf)
      for(let i = 0; i < 9; i++) {
        scanStopConf[i] = 0
      }

      await this.$store.state.impHandles.complexConf.writeValue(scanStopConf)

      this.$store.state.impHandles.complexData.removeEventListener('characteristicvaluechanged', 
                                                                        this.impedanceEventHandler)
      this.scanStarted = false

      //console.log("Impedance average = " + this.curImpAvg)   
    },

    parseScanConf() {
      const buf = new ArrayBuffer(9)
      let scanConf = new Uint8Array(buf)
      scanConf[0] = 0b00000001 // Enable - Default period
      scanConf[1] = this.form.nInc & 0xFF
      scanConf[2] = (this.form.nInc >> 8) & 0xFF
      scanConf[3] = this.form.fStep & 0xFF
      scanConf[4] = (this.form.fStep >> 8) & 0xFF
      scanConf[5] = this.form.fStart & 0xFF
      scanConf[6] = (this.form.fStart >> 8) & 0xFF
      scanConf[7] = (this.form.fStart >> 16) & 0xFF
      scanConf[8] = (this.form.fStart >> 24) & 0xFF

      return scanConf
    },

    impedanceEventHandler(event) {
      const targetUuid = event.target.uuid
      const rawData = event.target.value

      if(targetUuid === this.sImpedance.cComplexUuid) {
        let data = this.parseImpedanceData(rawData)
        this.$store.commit('updateImpData', data)
        this.updateChart()
      } else if(targetUuid === this.sImpedance.cCalibUuid) {
        let calibData = this.parseCalibData(rawData)
        console.log("Calibrated for midpoint freq " + calibData.fHz)
        console.log("Gain factor " + calibData.gain)
        this.gainFactor = calibData.gain
      } else {
        console.log('impedanceEventHandler: Unknown target uuid ' + targetUuid)
        console.log(rawData)
      }
    },

    parseImpedanceData(rawData) {
      let timestamp = (rawData.getUint8(0) | (rawData.getUint8(1) << 8) 
        | (rawData.getUint8(2) << 16) | (rawData.getUint8(3) << 24))

      let fHz = (rawData.getUint8(4) | (rawData.getUint8(5) << 8) 
        | (rawData.getUint8(6) << 16) | (rawData.getUint8(7) << 24))

      const int16View = new Int16Array(rawData.buffer)
      let real = int16View[4]
      let imag = int16View[5]

      //console.log("f: " + fHz + " real: " + real + " imag: " + imag)
      let {imp, mag} = this.calcImpedanceMagnitude(fHz, real, imag)
      //console.log(imp + " " + mag)

      if(this.curImpAvg == 0) {
        this.curImpAvg = imp 
      } else {
        this.curImpAvg = (this.curImpAvg + imp) / 2
      }

      console.log(real + ";" + imag + ";" + fHz + ";" + imp + ";" + mag + ";" + this.curImpAvg)

      return {ts: timestamp, fHz: fHz, real: real, imag: imag, imp: imp, mag: mag}
    },

    parseCalibData(rawData) {
        let fHz = (rawData.getUint8(0) | (rawData.getUint8(1) << 8) 
        | (rawData.getUint8(2) << 16) | (rawData.getUint8(3) << 24))

        const f32View = new Float32Array(rawData.buffer.slice(4))

        return {fHz: fHz, gain: f32View[0]}
    },

    calcImpedanceMagnitude(frequency, real, imag) {
      let magnitude = Math.sqrt( (Math.pow(real, 2) + Math.pow(imag, 2)) )
      let impedance = 1 / (this.gainFactor * magnitude)

      return {imp: impedance, mag: magnitude}
    },

    calcCurrentGain(real, imag) {
      let magnitude = Math.sqrt( (Math.pow(real, 2) + Math.pow(imag, 2)) )
      let currentGain = (1/this.gainRefResisor) / magnitude

      return {mag: magnitude, gain: currentGain}
    },

    async startCalibration() {
      console.log("startCalibration")
      if(this.submitDisable) return
      if(!this.$store.state.sImpedanceAvailable) return

      this.$store.state.impHandles.calib.addEventListener(
                                            'characteristicvaluechanged', 
                                            this.impedanceEventHandler)

      await this.$store.state.impHandles.calib.startNotifications()

      let scanConf = this.parseScanConf()
      await this.$store.state.impHandles.calib.writeValue(scanConf)
    },

    async updateChart() {
      let labels = this.$store.state.impData.fHz
      let realData = this.$store.state.impData.real
      let imagData = this.$store.state.impData.imag
      let impData = this.$store.state.impData.imp
      let magData = this.$store.state.impData.mag

      this.realChartData = {
        labels: labels,
        datasets: [{
          label: 'Real',
          lineTension: 0,
          borderWidth: 2,
          borderColor: '#2c3e50',
          pointBackgroundColor: '#2c3e50',
          backgroundColor: 'transparent',
          data: realData
        }]
      }

      this.imagChartData = {
        labels: labels,
        datasets: [{
          label: 'Imaginär',
          lineTension: 0,
          borderWidth: 2,
          borderColor: '#2c3e50',
          pointBackgroundColor: '#2c3e50',
          backgroundColor: 'transparent',
          data: imagData
        }]
      }

      this.impChartData = {
        labels: labels,
        datasets: [{
          label: 'Impedanz',
          lineTension: 0,
          borderWidth: 2,
          borderColor: '#2c3e50',
          pointBackgroundColor: '#2c3e50',
          backgroundColor: 'transparent',
          data: impData
        }]
      }

      this.magChartData = {
        labels: labels,
        datasets: [{
          label: 'Magnitude',
          lineTension: 0,
          borderWidth: 2,
          borderColor: '#2c3e50',
          pointBackgroundColor: '#2c3e50',
          backgroundColor: 'transparent',
          data: magData
        }]
      }

      if(labels.slice(-1)[0] == this.lastFrequency) {
        await this.stopScan()
      }
        
    },
  }, // methods

  async beforeDestroy() {
    if(this.$store.state.bleConnected) {
      await this.$store.state.impHandles.complexData.stopNotifications()
      await this.stopScan()
    }
  }
}
</script>

<style scoped>
.page-container {
  width: 100%;
  height: 100%;
  font-size: 14px;
}
.charts {
  display: inline-block;
  height: 66%;
  width: 95%;
  /*padding-bottom: 10px;*/
}
.chart-container {
  display: inline-block;
  height: 32%;
  width: 95%;
  padding-bottom: 10px;
}
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 28%;
  font-size: 14px;
}
.col-style {
  display: flex;
  justify-content: center;
  align-items: center;  
}
.input-style {
  width: 50%;
}
.submit-button {
  margin-top: 13px;
  margin-right: 10px;
}
</style>